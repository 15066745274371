@keyframes pulse {
  from {
    opacity: 1;
    transform: scale3d(.8, .8, .8)
  }

  50% {
    transform: scale3d(1, 1, 1);
    opacity: 1
  }

  to {
    opacity: 1;
    transform: scale3d(.8, .8, .8)
  }
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1)
  }

  10%, 5% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -5deg)
  }

  15%, 25%, 35%, 45% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 5deg)
  }

  20%, 30%, 40% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -5deg)
  }

  50% {
    transform: scale3d(1, 1, 1)
  }
}

@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}