.auth-code-wrapper {
	display: flex;
	justify-content: space-between;
	margin: .5rem 0 2rem 0;

	input {
		width: 3rem;
		height: 3rem;
		border-radius: .25rem;
		text-align: center;
		outline: 2px solid transparent;
		outline-offset: 2px;
		color: transparent;
		text-shadow: 0 0 0 gray;

		&:focus {
			//background: $ghost-white-v2;
			//border-color: $light-steel-blue;
		}
	}
}

.animated-checkmark {
	//width: 100px;
	//height: 100px;
	display: block;
	stroke-width: 4;
	stroke: $green;
	stroke-miterlimit: 10;
	border-radius: 50%;
	box-shadow: inset 0 0 0 $green;
	animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
	position:relative;
	//top: 5px;
	//right: 5px;
	//margin: 0 auto;
}
.checkmark-circle {
	stroke-dasharray: 180;
	stroke-dashoffset: 180;
	stroke-width: 4;
	stroke-miterlimit: 10;
	stroke: $green;
	fill: #fff;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark-check {
	transform-origin: 50% 50%;
	stroke-dasharray: 50;
	stroke-dashoffset: 50;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes scale {
	0%, 100% {
		transform: none;
	}

	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fill {
	100% {
		box-shadow: inset 0 0 0 30px $green;
	}
}

.sign-up-successfully {
	//text-align : center;
	//.thanks {
	//	//color       : $green;
	//	//font-weight : 700;
	//	//font-size   : 40px;
	//	//margin      : 20px 0 30px 0;
	//}
	//p {
	//	//font-size : 20px;
	//}
	svg {
		//display : block;
		//width   : 150px;
		//margin  : 20px auto;
	}
	/*path {
		fill            : none;
		stroke          : $green;
		stroke-width    : 20;
		stroke-linecap  : round;
		stroke-linejoin : round;
	}
	polyline {
		fill            : none;
		stroke          : $green;
		stroke-width    : 20;
		stroke-linecap  : round;
		stroke-linejoin : round;
	}
	.circle {
		stroke-dasharray : 1260;
		animation        : circle 1s linear;
	}
	.check {
		stroke-dasharray : 430;
		animation        : check 1s linear;
	}*/
}

//@keyframes circle {
//	0%, 40% {
//		stroke-dashoffset : -1260;
//	}
//	100% {
//		stroke-dashoffset : 0;
//	}
//}
//
//@keyframes check {
//	0% {
//		stroke-dashoffset : 430;
//	}
//	40% {
//		stroke-dashoffset : 0;
//	}
//}