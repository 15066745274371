$prefix: "citadel-";

//$font-google: "Inter"; //Fira Sans
$font-google: "Bitter"; // Inknut Antiqua
$font-google-heading: 'Playfair Display';
$font-local: null;
$font-icons: ();

$font-family-sans-serif-heading: unquote("#{if($font-local, "#{$font-local}, ", ' ')}#{if($font-google-heading, "#{$font-google-heading}, ", ' ')}") -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif !default;

//Fonts
//$font-size-75: 0.75rem !default;
//$font-size-100: 0.875rem !default;
//$font-size-200: 1rem !default;
//$font-size-300: 1.25rem !default;
//$font-size-400: 1.5rem !default;
//$font-size-500: 1.75rem !default;
//$font-size-600: 2rem !default;
//$font-size-700: 2.5rem !default;

$font-size-75: 0.875rem;
$font-size-100: 1rem;
$font-size-200:  1.25rem;
$font-size-300:  1.5rem;
$font-size-400:  1.75rem;
$font-size-500:  2rem;
$font-size-600:  2.5rem;
$font-size-700: 2.75rem;

$font-size-base: 0.975rem;

$h1-font-size: 1.5rem + 0.1;
$h1-line-height: 2rem + 0.1;

$h2-font-size: 1.25rem + 0.1;
$h2-line-height: 1.75rem + 0.1;

$h3-font-size: 1rem + 0.1;
$h3-line-height: 1.5rem + 0.1;

$h4-font-size: 0.875rem + 0.1;
$h4-line-height: 1.25rem + 0.1;

$h5-font-size: 0.75rem + 0.1;
$h5-line-height: 1rem + 0.1;

$h6-font-size: 0.625rem + 0.1;
$h6-line-height: 1rem + 0.1;

// Spacers
//$spacer-0: 0rem  + 0.25;
//$spacer-1: 0.25rem + 0.25;
//$spacer-2: 0.5rem + 0.25;
//$spacer-3: 1rem + 0.25;
//$spacer-4: 1.5rem + 0.25;
//$spacer-5: 2rem + 0.25;
//$spacer-6: 3rem + 0.25;
//$spacer-7: 5rem + 0.25;
//$spacer-8: 8rem + 0.25;
//
//$spacer: $spacer-3 + 0.25;

//$gray-50: #f5f7fa;
//$gray-100: #f1f5f8;
//$gray-200: #e1e8f0;
//$gray-300: #cbd4e1;
//$gray-400: #94a2b8;
//$gray-500: #64748a;
//$gray-600: #465569;
//$gray-700: #324155;
//$gray-800: #1e283b;
//$gray-900: #0f162a;

//$gray-50: #aaaaaaa;
//$gray-100: #aaaaaaa;
//$gray-200: #aaaaaaa;
//$gray-300: #aaaaaaa;
//$gray-400: #aaaaaaa;
//$gray-500: #aaaaaaa;
//$gray-600: #aaaaaaa;
//$gray-700: #aaaaaaa;
//$gray-800: #aaaaaaa;
//$gray-900: #aaaaaaa;

$gray-50: #fafafa;
$gray-100: #f5f9fc;
$gray-200: #e7eaf0;
$gray-300: #cfd6df;
$gray-400: #abb6c5;
$gray-500: #8898a9;
$gray-600: #6b7b93;
$gray-700: #525f7f;
$gray-800: #2d3748;
$gray-900: #16192c;

$body-bg: #fff;//#f5f7fa;
$body-color: #1e293b;

$blue: #0098ff;
$azure: #00bdff;
$indigo: #5c5ff5;
$purple: #8956ff;
$pink: #ff569a;
$red: #eb3a5a;
$orange: #ff8a00;
$yellow: #ffba00;
$lime: #26de81;
$green: #00cd87;
$teal: #22dcdc;
$cyan: #00d2ff;

$light: $gray-50;
$dark: $gray-800;

$color-blue: #00a8ff;
$color-azure: #00bdff;
$color-indigo: #706fd3;
$color-purple: #a55eea;
$color-pink: #FC427B;
$color-red: #eb3b5a;
$color-orange: #fd9644;
$color-yellow: #fed330;
$color-lime: #26de81;
$color-green: #20bf6b;
$color-teal: #2bcbba;
$color-cyan: #01a3a4;

$border-color: $gray-200;

$text-secondary: $gray-600;

$primary: $blue;
$secondary: $text-secondary;
$success: $green;
$info: $azure;
$warning: $orange;
$danger: $red;

$theme-colors: (
	"primary": $primary,
	"secondary": $text-secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
	"muted": $text-secondary
);

$extra-colors: (
	"blue": $blue,
	//"azure": $azure,
	//"indigo": $indigo,
	//"purple": $purple,
	//"pink": $pink,
	"red": $red,
	"orange": $orange,
	"yellow": $yellow,
	"lime": $lime,
	"green": $green,
	"teal": $teal,
	"cyan": $cyan,
);

$social-colors: (
	//"facebook": #1877f2,
	//"twitter": #1da1f2,
	//"linkedin": #0a66c2,
	//"google": #dc4e41,
	//"youtube": #ff0000,
	//"vimeo": #1ab7ea,
	//"dribbble": #ea4c89,
	//"github": #181717,
	//"instagram": #e4405f,
	//"pinterest": #bd081c,
	//"vk": #6383a8,
	//"rss": #ffa500,
	//"flickr": #0063dc,
	//"bitbucket": #0052cc,
	//"tabler": #0054a6,
);

//$grid-row-columns: 8;

//Fonts
//$h1-font-size: 1.5rem;
//
//$h2-font-size: 1.25rem;
//
//$h3-font-size: 1rem;
//
//$h4-font-size: 0.875rem;
//
//$h5-font-size: 0.75rem;
//
//$h6-font-size: 0.625rem;

// Nav
$nav-link-font-weight: 500;

// Navbar
$navbar-brand-image-height: 2.5rem;
$nav-link-color: var(--#{$prefix}emphasis-color);
$navbar-light-hover-color: rgba(var(--#{$prefix}emphasis-color-rgb), .8);

// Dropdown
$dropdown-link-hover-bg: rgba(var(--#{$prefix}secondary-rgb), 0.04);

// Sidebar
$sidebar-width: 16.5rem;
$sidebar-mini-width: 4.5rem;

// Buttons
$btn-padding-x-sm: .75rem;
$btn-padding-y-sm: .285rem;
$btn-border-radius-sm: 4px;
$btn-border-color: var(--#{$prefix}border-color-translucent);

// Cards

// Forms
$input-focus-box-shadow: 0;
$form-select-focus-width: 0;
$form-check-input-focus-box-shadow: none;

// Pagination
$pagination-focus-bg: none;
$pagination-focus-box-shadow: none;

// Offcanvas
$offcanvas-border-width: 0;
$offcanvas-horizontal-width: 320px;

// Modals
$modal-xxl: 1320px;
$btn-close-focus-shadow: none;

// Avatars
$avatar-sizes: (
	"xxs": (
		size: 1rem,
		font-size: .5rem,
		icon-size: .75rem,
		status-size: .25rem
	),
	"xs": (
		size: 1.25rem,
		font-size: $h6-font-size,
		icon-size: 1rem,
		status-size: .375rem
	),
	"sm": (
		size: 2rem,
		font-size: $h5-font-size,
		icon-size: 1.25rem,
		status-size: .5rem
	),
	"md": (
		size: 2.5rem,
		font-size: $h4-font-size,
		icon-size: 1.5rem,
		status-size: .75rem
	),
	"lg": (
		size: 3rem,
		font-size: $h2-font-size,
		icon-size: 2rem,
		status-size: .75rem
	),
	"xlg": (
		size: 4rem,
		font-size: 1.25rem,
		icon-size: 2rem,
		status-size: 1rem
	),
	"xl": (
		size: 5rem,
		font-size: 2rem,
		icon-size: 3rem,
		status-size: 1rem
	),
	"2xl": (
		size: 7rem,
		font-size: 3rem,
		icon-size: 5rem,
		status-size: 1rem
	),
);

// Lists
$input-group-addon-bg: var(--#{$prefix}bg-surface);