.btn {
	--#{$prefix}btn-icon-size: #{$icon-size};
	--#{$prefix}btn-bg: var(--#{$prefix}bg-surface);
	--#{$prefix}btn-color: var(--#{$prefix}body-color);
	--#{$prefix}btn-border-color: #{$btn-border-color};
	--#{$prefix}btn-hover-bg: var(--#{$prefix}btn-bg);
	--#{$prefix}btn-hover-border-color: var(--#{$prefix}border-color-active);
	--#{$prefix}btn-box-shadow: var(--#{$prefix}box-shadow-input);
	--#{$prefix}btn-active-color: #{$active-color};
	--#{$prefix}btn-active-bg: #{$active-bg};
	--#{$prefix}btn-active-border-color: #{$active-border-color};

	display: inline-flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	box-shadow: var(--#{$prefix}btn-box-shadow);

	.icon {
		width: var(--#{$prefix}btn-icon-size);
		height: var(--#{$prefix}btn-icon-size);
		min-width: var(--#{$prefix}btn-icon-size);
		margin: 0 calc(var(--#{$prefix}btn-padding-x) / 2) 0 calc(var(--#{$prefix}btn-padding-x) / -4);
		vertical-align: bottom;
		color: inherit;
		pointer-events: none;
	}

	.avatar {
		width: var(--#{$prefix}btn-icon-size);
		height: var(--#{$prefix}btn-icon-size);
		margin: 0 calc(var(--#{$prefix}btn-padding-x) / 2) 0 calc(var(--#{$prefix}btn-padding-x) / -4);
	}

	.icon-right {
		margin: 0 calc(var(--#{$prefix}btn-padding-x) / -4) 0 calc(var(--#{$prefix}btn-padding-x) / 2);
	}

	.badge {
		top: auto;
	}

	.btn-check + &:hover {
		color: var(--#{$prefix}btn-hover-color);
		background-color: var(--#{$prefix}btn-hover-bg);
		border-color: var(--#{$prefix}btn-hover-border-color);
	}
}

.btn-link {
	color: $link-color;
	background-color: transparent;
	border-color: transparent;
	box-shadow: none;
	text-align: left;

	.icon {
		color: inherit;
		pointer-events: none;
	}

	&:hover {
		color: $link-hover-color;
		border-color: transparent;
	}
}

//
// Button color variations
//
@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@if $color == 'dark' {
			--#{$prefix}btn-border-color: var(--#{$prefix}dark-mode-border-color);
			--#{$prefix}btn-hover-border-color: var(--#{$prefix}dark-mode-border-color-active);
			--#{$prefix}btn-active-border-color: var(--#{$prefix}dark-mode-border-color-active);
		} @else {
			--#{$prefix}btn-border-color: transparent;
			--#{$prefix}btn-hover-border-color: transparent;
			--#{$prefix}btn-active-border-color: transparent;
		}

		--#{$prefix}btn-color: var(--#{$prefix}#{$color}-fg);
		--#{$prefix}btn-bg: var(--#{$prefix}#{$color});
		--#{$prefix}btn-hover-color: var(--#{$prefix}#{$color}-fg);
		--#{$prefix}btn-hover-bg: rgba(var(--#{$prefix}#{$color}-rgb), .8);
		--#{$prefix}btn-active-color: var(--#{$prefix}#{$color}-fg);
		--#{$prefix}btn-active-bg: rgba(var(--#{$prefix}#{$color}-rgb), .8);
		--#{$prefix}btn-disabled-bg: var(--#{$prefix}#{$color});
		--#{$prefix}btn-disabled-color: var(--#{$prefix}#{$color}-fg);
		--#{$prefix}btn-box-shadow: var(--#{$prefix}box-shadow-input);
	}

	.btn-outline-#{$color} {
		--#{$prefix}btn-color: var(--#{$prefix}#{$color});
		--#{$prefix}btn-bg: transparent;
		--#{$prefix}btn-border-color: var(--#{$prefix}#{$color});
		--#{$prefix}btn-hover-color: var(--#{$prefix}#{$color}-fg);
		--#{$prefix}btn-hover-border-color: transparent;
		--#{$prefix}btn-hover-bg: var(--#{$prefix}#{$color});
		--#{$prefix}btn-active-color: var(--#{$prefix}#{$color}-fg);
		--#{$prefix}btn-active-bg: var(--#{$prefix}#{$color});
		--#{$prefix}btn-disabled-color: var(--#{$prefix}#{$color});
		--#{$prefix}btn-disabled-border-color: var(--#{$prefix}#{$color});
	}
}

@each $name, $color in $theme-colors {
	.btn-ghost-#{$name} {
		--#{$prefix}btn-color: var(--#{$prefix}#{$name});
		--#{$prefix}btn-bg: transparent;
		--#{$prefix}btn-border-color: transparent;
		--#{$prefix}btn-hover-color: var(--#{$prefix}#{$name}-fg);
		--#{$prefix}btn-hover-bg: var(--#{$prefix}#{$name});
		--#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$name});
		--#{$prefix}btn-active-color: var(--#{$prefix}#{$name}-fg);
		--#{$prefix}btn-active-bg: var(--#{$prefix}#{$name});
		--#{$prefix}btn-active-border-color: transparent;
		--#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
		--#{$prefix}btn-disabled-color: var(--#{$prefix}#{$name});
		--#{$prefix}btn-disabled-bg: transparent;
		--#{$prefix}btn-disabled-border-color: transparent;
		--#{$prefix}gradient: none;
		--#{$prefix}btn-box-shadow: none;
	}
}

//
// Button sizes
//
.btn-sm, .btn-group-sm > .btn {
	--#{$prefix}btn-line-height: 1.5;
	--#{$prefix}btn-icon-size: 1.085rem;
}

.btn-lg, .btn-group-lg > .btn {
	--#{$prefix}btn-line-height: 1.5;
	--#{$prefix}btn-icon-size: 2rem;
}

//
// Button shapes
//
.btn-pill {
	padding-right: 1.5em;
	padding-left: 1.5em;
	border-radius: 10rem;

	&[class*="btn-icon"] {
		padding: 0.375rem 15px;
	}
}

.btn-square {
	border-radius: 0;
}

//
// Icon button
//
.btn-icon {
	min-width: calc((var(--#{$prefix}btn-line-height) * var(--#{$prefix}btn-font-size)) + (var(--#{$prefix}btn-padding-y) * 2) + (var(--#{$prefix}btn-border-width) * 2));
	min-height: calc((var(--#{$prefix}btn-line-height) * var(--#{$prefix}btn-font-size)) + (var(--#{$prefix}btn-padding-y) * 2) + (var(--#{$prefix}btn-border-width) * 2));
	padding-left: 0;
	padding-right: 0;

	.icon {
		margin: calc(-1 * var(--#{$prefix}btn-padding-x));
	}
}

//
// Button list
//
.btn-list {
	@include elements-list;
}

//
// Button floating
//
.btn-floating {
	position: fixed;
	z-index: $zindex-fixed;
	bottom: 1.5rem;
	right: 1.5rem;
	border-radius: $border-radius-pill;
}

//
// Button loading
//
.btn-loading {
	position: relative;
	color: transparent !important;
	text-shadow: none !important;
	pointer-events: none;

	> * {
		opacity: 0;
	}

	&:after {
		content: "";
		display: inline-block;
		vertical-align: text-bottom;
		border: $spinner-border-width var(--#{$prefix}border-style) currentColor;
		border-right-color: transparent;
		border-radius: $border-radius-pill;
		color: var(--#{$prefix}btn-color);
		position: absolute;
		width: var(--#{$prefix}btn-icon-size);
		height: var(--#{$prefix}btn-icon-size);
		left: calc(50% - var(--#{$prefix}btn-icon-size) / 2);
		top: calc(50% - var(--#{$prefix}btn-icon-size) / 2);
		animation: spinner-border .75s linear infinite;
	}
}

//
// Action button
//
.btn-action {
	padding: 0;
	border: 0;
	color: var(--#{$prefix}secondary);
	display: inline-flex;
	width: 2rem;
	height: 2rem;
	align-items: center;
	justify-content: center;
	border-radius: var(--#{$prefix}border-radius);
	background: transparent;

	&:after {
		content: none;
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}

	&:hover,
	&.show {
		color: var(--#{$prefix}body-color);
		background: var(--#{$prefix}active-bg);
	}

	&.show {
		color: var(--#{$prefix}primary);
	}

	.icon {
		margin: 0;
		width: 1.25rem;
		height: 1.25rem;
		font-size: 1.25rem;
		stroke-width: 2;
		pointer-events: none;
	}
}

.btn-actions {
	display: flex;
}

.btn-toggle-sidebar {
	min-height: 1.25rem;
	min-width: 1.25rem;
	margin: 0 -.25rem 0 2rem;
	transform: rotate(90deg);
	transition: transform $transition-time ease;
}