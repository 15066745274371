%validation-lite {
  border-color: var(--#{$prefix}border-color) !important;
}

@each $state, $data in $form-validation-states {
  .form-control.is-#{$state}-lite {
    @extend %validation-lite;
  }

  .form-select.is-#{$state}-lite {
    @extend %validation-lite;
  }
}

.form-error {
  //width: 100%;
  margin-top: 0.25rem;
  font-size: 85.71428571%;
  color: $red;
}