@mixin navbar-vertical-nav {
	.navbar-collapse,
	.offcanvas-body {
		flex-direction: column;

		[class^="container"] {
			flex-direction: column;
			align-items: stretch;
			padding: 0;
		}

		.navbar-nav {
			margin-left: 0;
			margin-right: 0;

			.nav-link {
				padding: .65rem calc(#{$container-padding-x} / 2);
				justify-content: flex-start;
			}
		}

		.dropdown-menu-columns {
			flex-direction: column;
		}

		.dropdown-menu {
			padding: 0;
			background: transparent;
			position: static;
			color: inherit;
			box-shadow: none;
			border: none;
			min-width: 0;
			margin: 0;

			.dropdown-item {
				min-width: 0;
				display: flex;
				width: auto;
				padding-left: add(calc(#{$container-padding-x} / 2), 1.75rem);
				color: inherit;

				&.active,
				&:active {
					background: var(--#{$prefix}navbar-active-bg);
				}
			}

			.dropdown-menu .dropdown-item {
				padding-left: add(calc(#{$container-padding-x} / 2), 3.25rem);
			}

			.dropdown-menu .dropdown-menu .dropdown-item {
				padding-left: add(calc(#{$container-padding-x} / 2), 4.75rem);
			}
		}

		.dropdown-toggle:after {
			margin-left: auto;
		}

		//.nav-item.active:after {
		//  border-bottom-width: 0;
		//  border-left-width: 3px;
		//  right: auto;
		//  top: 0;
		//  bottom: 0;
		//}
	}
}

/**
Navbar
 */
.navbar {
	--#{$prefix}navbar-bg: var(--#{$prefix}bg-surface);
	--#{$prefix}navbar-border-width: #{$navbar-border-width};
	--#{$prefix}navbar-active-border-color: #{$navbar-active-border-color};
	--#{$prefix}navbar-active-bg: #{$navbar-light-active-bg};
	--#{$prefix}navbar-color: var(--#{$prefix}body-color);
	--#{$prefix}navbar-border-color: #{$navbar-border-color};
	align-items: stretch;
	min-height: $navbar-height;
	box-shadow: inset 0 calc(-1 * var(--#{$prefix}navbar-border-width)) 0 0 var(--#{$prefix}navbar-border-color);
	//box-shadow: inset 1px 0 0 $gray-100, inset 0 -1px 0px $gray-200;
	background: var(--#{$prefix}navbar-bg);
	color: var(--#{$prefix}navbar-color);

	.navbar-collapse & {
		flex-grow: 1;
	}

	&.collapsing {
		min-height: 0;
	}

	.dropdown-menu {
		position: absolute;
		z-index: $zindex-fixed;
	}

	.navbar-nav {
		min-height: subtract($navbar-height, 2 * $navbar-padding-y);

		&.sidebar-nav {
			--#{$prefix}nav-link-font-size: 0.9375rem;

			.nav-link-icon {
				width: 1.5rem;
				height: 1.5rem;
				margin-right: 0.85rem;
			}

			.icon {
				--#{$prefix}icon-size: 1.5rem;
			}
		}

		.nav-link {
			position: relative;
			min-width: 2rem;
			min-height: 2rem;
			justify-content: center;
			border-radius: var(--#{$prefix}border-radius);


			.badge {
				position: absolute;
				top: .375rem;
				right: .375rem;
				transform: translate(50%, -50%);
			}
		}
	}

	&.navbar-profile {
		.navbar-nav {
			.nav-link {
				.badge {
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.navbar-nav {
	margin: 0;
	padding: 0;
}

.navbar-expand {
	@each $breakpoint in map-keys($grid-breakpoints) {
		$next: breakpoint-next($breakpoint, $grid-breakpoints);
		$infix: breakpoint-infix($next, $grid-breakpoints);

		&#{$infix} {
			@include media-breakpoint-down(breakpoint-next($breakpoint)) {
				@include navbar-vertical-nav;
			}

			@include media-breakpoint-up($next) {
				.navbar-collapse {
					width: auto;
					flex: 1 1 auto;
				}

				.nav-item.active {
					position: relative;

					//&:after {
					//  content: "";
					//  position: absolute;
					//  left: 0;
					//  right: 0;
					//  bottom: -.25rem;
					//  border: 0 var(--#{$prefix}border-style) var(--#{$prefix}navbar-active-border-color);
					//  border-bottom-width: 2px;
					//}
				}

				//&.navbar-vertical {
				//	box-shadow: inset calc(-1 * var(--#{$prefix}navbar-border-width)) 0 0 0 var(--#{$prefix}navbar-border-color);
				//	//box-shadow: inset 1px 0 0 var(--#{$prefix}navbar-border-color), inset 0 -1px 0px var(--#{$prefix}navbar-border-color);
				//
				//	&.navbar-right {
				//		box-shadow: inset calc(1 * var(--#{$prefix}navbar-border-width)) 0 0 0 var(--#{$prefix}navbar-border-color);
				//	}
				//}

				//&.navbar-vertical {
				//	~ .navbar,
				//	~ .page-wrapper {
				//		margin-left: var(--#{$prefix}sidebar-width);
				//		transition: margin $transition-time ease;
				//	}
				//}

				//&.navbar-vertical.navbar-right {
				//	~ .navbar,
				//	~ .page-wrapper {
				//		margin-left: 0;
				//		margin-right: $sidebar-width;
				//	}
				//}
			}
		}
	}
}

/**
Navbar brand
 */
.navbar-brand {
	display: inline-flex;
	align-items: center;
	font-weight: $navbar-brand-font-weight;
	margin: 0;
	line-height: 1;
	gap: $spacer-2;
}

.navbar-brand-image {
	height: $navbar-brand-image-height;
	width: auto;
}

.svg-logo {
	max-width: 100%;

	.c1, .c2 {
		transition: 0.4s cubic-bezier(.17, .67, .83, .67);
	}

	&:hover {
		.c1 {
			fill: var(--#{$prefix}info);
		}

		.c2 {
			fill: var(--#{$prefix}primary);
		}
	}


}

@include color-mode(dark) {
	.svg-logo {
		.txt-main {
			fill: var(--#{$prefix}white);
		}
	}
}

/**
Navbar toggler
 */
.navbar-toggler {
	border: 0;
	width: $navbar-brand-image-height;
	height: $navbar-brand-image-height;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbar-toggler-icon {
	height: 2px;
	width: 1.25em;
	background: currentColor;
	border-radius: 10px;
	@include transition(top $navbar-toggler-animation-time $navbar-toggler-animation-time, bottom $navbar-toggler-animation-time $navbar-toggler-animation-time, transform $navbar-toggler-animation-time, opacity 0s $navbar-toggler-animation-time);
	position: relative;

	&:before,
	&:after {
		content: "";
		display: block;
		height: inherit;
		width: inherit;
		border-radius: inherit;
		background: inherit;
		position: absolute;
		left: 0;
		@include transition(inherit);
	}

	&:before {
		top: -.45em;
	}

	&:after {
		bottom: -.45em;
	}

	.navbar-toggler[aria-expanded="true"] & {
		transform: rotate(45deg);
		@include transition(top $transition-time, bottom $transition-time, transform $transition-time $transition-time, opacity 0s $transition-time);

		&:before {
			top: 0;
			transform: rotate(-90deg);
		}

		&:after {
			bottom: 0;
			opacity: 0;
		}
	}
}

/**
Navbar transparent
 */
.navbar-transparent {
	--#{$prefix}navbar-border-color: transparent !important;
	background: transparent !important;
}

/**
Navbar nav
 */
.navbar-nav {
	align-items: stretch;

	.nav-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

/**
Navbar side
 */
.navbar-side {
	margin: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

/**
Navbar vertical
 */
@if $enable-navbar-vertical {
	.navbar-vertical {
		@include media-breakpoint-up(lg) {
			width: var(--#{$prefix}sidebar-width);
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: $zindex-fixed;
			align-items: flex-start;
			@include transition(all $transition-time); //width, transform $transition-time
			overflow-y: scroll;
			padding: 0;

			&.navbar-profile {
				position: relative;
				width: 100%;
			}

			&.navbar-right {
				left: auto;
				right: 0;
			}

			.navbar-brand {
				padding: (($navbar-height - $navbar-brand-image-height) * .5) 0;
				justify-content: center;
			}

			.navbar-collapse {
				align-items: stretch;
			}

			.navbar-nav {
				flex-direction: column;
				flex-grow: 1;
				min-height: auto;

				.nav-link {
					padding-top: .5rem;
					padding-bottom: .5rem;
				}
			}

			> [class^="container"] {
				flex-direction: column;
				align-items: stretch;
				min-height: 100%;
				justify-content: flex-start;
				padding: 0;
			}

			~ .page {
				padding-left: $sidebar-width;

				[class^="container"] {
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}
			}

			&.navbar-right ~ .page {
				padding-left: 0;
				padding-right: $sidebar-width;
			}

			@include navbar-vertical-nav;

			box-shadow: inset calc(-1 * var(--#{$prefix}navbar-border-width)) 0 0 0 var(--#{$prefix}navbar-border-color);

			~ .navbar,
			~ .page-wrapper {
				margin-left: var(--#{$prefix}sidebar-width);
				transition: margin $transition-time ease;
			}
		}
		@include media-breakpoint-down(lg) {
			.navbar-nav {
				.nav-link {
					padding: .5rem calc(calc(var(--#{$prefix}page-padding) * 2) / 2);
					justify-content: flex-start;
				}
			}

		}


		.sidebar-logo {
			height: 3.35rem;
			justify-content: space-between;
		}

		//&.navbar-expand {
		//	@each $breakpoint in map-keys($grid-breakpoints) {
		//		$next: breakpoint-next($breakpoint, $grid-breakpoints);
		//		$infix: breakpoint-infix($next, $grid-breakpoints);
		//
		//		&#{$infix} {
		//			@include media-breakpoint-up($next) {
		//
		//			}
		//		}
		//	}
		//}
	}
}

.navbar-toolbar {
	.btn {
		min-height: 2rem;
		min-width: 2rem;
		border: 0;
		box-shadow: none;

		.icon {
			stroke-width: 2.25;
		}

		&.toggle-color-scheme {
			transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);

			&:hover {
				transform: rotate(-25deg);
			}
		}
	}
}

.navbar-overlap {
	&:after {
		content: "";
		height: $navbar-overlap-height;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		background: inherit;
		z-index: -1;
		box-shadow: inherit;
	}
}