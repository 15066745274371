/**
Legend
 */
.legend {
  --#{$prefix}legend-size: #{$legend-size};
  display: inline-block;
  background: $legend-bg;
  width: var(--#{$prefix}legend-size);
  height: var(--#{$prefix}legend-size);
  border-radius: $legend-border-radius;
  border: 1px solid var(--#{$prefix}border-color-translucent);
}
