.footer {
  border-top: var(--#{$prefix}border-width) var(--#{$prefix}border-style) $footer-border-color;
  background-color: $footer-bg;
  padding: $footer-padding-y 0;
  color: $footer-color;
  margin-top: auto;
}

.footer-transparent {
  background-color: transparent;
  border-top: 0;
}