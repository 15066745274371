.row > * {
  min-width: 0;
}

.col-separator {
  border-left: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color);
}

.container {
  @each $variation, $size in $container-variations {
    &-#{$variation} {
      @include make-container();
      max-width: $size;
    }
  }
}

@each $name, $value in (0: 0, sm: .375rem, md: 1.5rem, lg: 3rem) {
  .row-#{$name} {
    margin-right: (-$value);
    margin-left: -($value);

    > .col,
    > [class*="col-"] {
      padding-right: $value;
      padding-left: $value;
    }

    .card {
      margin-bottom: 2 * $value;
    }
  }
}

.row-deck {
  > .col,
  > [class*="col-"] {
    display: flex;
    align-items: stretch;

    .card {
      flex: 1 1 auto;
    }
  }
}

.row-cards {
  --#{$prefix}gutter-x: #{$cards-grid-gap};
  --#{$prefix}gutter-y: #{$cards-grid-gap};
  min-width: 0;

  .row-cards {
    flex: 1;
  }
}

@each $name, $size in map-merge((null: $spacer), $spacers) {
  $name-prefixed: if($name == null, null, '-#{$name}');

  .space-y#{$name-prefixed} {
    display: flex;
    flex-direction: column;
    gap: $size;
  }

  .space-x#{$name-prefixed} {
    display: flex;
    gap: $size;
  }
}

@each $name, $size in map-merge((null: $spacer), $spacers) {
  $name-prefixed: if($name == null, null, '-#{$name}');

  .divide-y#{$name-prefixed} {
    > :not(template) ~ :not(template) {
      border-top: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color-translucent) !important;
    }

    > :not(template):not(:first-child) {
      padding-top: $size !important;
    }

    > :not(template):not(:last-child) {
      padding-bottom: $size !important;
    }
  }

  .divide-x#{$name-prefixed} {
    > :not(template) ~ :not(template) {
      border-left: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color-translucent) !important;
    }

    > :not(template):not(:first-child) {
      padding-left: $size !important;
    }

    > :not(template):not(:last-child) {
      padding-right: $size !important;
    }
  }
}

.divide-y-fill {
  display: flex;
  flex-direction: column;
  height: 100%;

  > :not(template) {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}