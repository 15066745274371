@import "typo/hr";

.lead {
  line-height: 1.4;
}

a {
  text-decoration-skip-ink: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

h1,
.h1 {
  font-size: var(--#{$prefix}font-size-h1);
  line-height: var(--#{$prefix}line-height-h1);
}

h2,
.h2 {
  font-size: var(--#{$prefix}font-size-h2);
  line-height: var(--#{$prefix}line-height-h2);
}

h3,
.h3 {
  font-size: var(--#{$prefix}font-size-h3);
  line-height: var(--#{$prefix}line-height-h3);
}

h4,
.h4 {
  font-size: var(--#{$prefix}font-size-h4);
  line-height: var(--#{$prefix}line-height-h4);
}

h5,
.h5 {
  font-size: var(--#{$prefix}font-size-h5);
  line-height: var(--#{$prefix}line-height-h5);
}

h6,
.h6 {
  font-size: var(--#{$prefix}font-size-h6);
  line-height: var(--#{$prefix}line-height-h6);
}

strong,
.strong,
b {
  font-weight: $headings-font-weight;
}

blockquote {
  padding-left: 1rem;
  border-left: 2px var(--#{$prefix}border-style) var(--#{$prefix}border-color);

  p {
    margin-bottom: 1rem;
  }

  cite {
    display: block;
    text-align: right;

    &:before {
      content: "— ";
    }
  }
}

ul, ol {
  padding-left: 1.5rem;
}

hr {
  margin: 2rem 0;
}

dl {
  dd {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

pre {
  padding: $pre-padding;
  background: $pre-bg;
  color: $pre-color;
  border-radius: var(--#{$prefix}border-radius);

  code {
    background: transparent;
  }
}

code {
  background: var(--#{$prefix}code-bg);
  padding: 2px 4px;
  border-radius: var(--#{$prefix}border-radius);
}

kbd,
.kbd {
  border: $kbd-border;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  font-size: $kbd-font-size;
  font-weight: $kbd-font-weight;
  line-height: 1;
  vertical-align: baseline;
  border-radius: $kbd-border-radius;
}

img {
  max-width: 100%;
}

.list-unstyled {
  margin-left: 0;
}


/**
Selection
 */
::selection {
  background-color: rgba(var(--#{$prefix}primary-rgb), .16);
}

/**
Links
 */
[class^="link-"], [class*=" link-"] {
  &.disabled {
    color: $nav-link-disabled-color !important;
    pointer-events: none;
  }
}

/**
Subheader
 */
.subheader {
  @include subheader;
}
