// stylelint-disable property-no-vendor-prefix
body {
  letter-spacing: $body-letter-spacing;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 0;
  position: relative;
  min-height: 100%;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media print {
    background: transparent;
  }
}

@include scrollbar;

//
// Fluid container
//
.layout-fluid {
  .container,
  [class^="container-"],
  [class*=" container-"] {
    max-width: 100%;
  }
}

//
// Boxed container
//
.layout-boxed {
  --#{$prefix}theme-boxed-border-radius: 0;
  --#{$prefix}theme-boxed-width: #{map-get($container-max-widths, xxl)};

  @include media-breakpoint-up(md) {
    background: $dark linear-gradient(to right, rgba(#fff, .1), transparent) fixed;
    padding: 1rem;
    --#{$prefix}theme-boxed-border-radius: #{$border-radius};
  }

  .page {
    margin: 0 auto;
    max-width: var(--#{$prefix}theme-boxed-width);
    border-radius: var(--#{$prefix}theme-boxed-border-radius);
    color: var(--#{$prefix}body-color);

    @include media-breakpoint-up(md) {
      border: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color);
      background: var(--#{$prefix}body-bg);
    }

    > .navbar:first-child {
      border-top-left-radius: var(--#{$prefix}theme-boxed-border-radius);
      border-top-right-radius: var(--#{$prefix}theme-boxed-border-radius);
    }
  }
}