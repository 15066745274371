@if $font-google {
  $google-font-url: "https://fonts.googleapis.com/css2?family=" + str-replace($font-google, " ", "+") + ":wght@300;400;500;600;700&display=swap" !default;
  @import url($google-font-url);
}

@if $font-google-heading {
  $google-font-heading-url: "https://fonts.googleapis.com/css2?family=" + str-replace($font-google-heading, " ", "+") + ":wght@300;400;500;600;700&display=swap" !default;
  @import url($google-font-heading-url);
}

@if $font-google-monospaced {
  $google-font-monospaced-url: "https://fonts.googleapis.com/css2?family=" + str-replace($font-google-monospaced, " ", "+") + ":wght@300;400;500;600;700&display=swap" !default;
  @import url($google-font-monospaced-url);
}

@if $font-icons {
  @each $icon-font in $font-icons {
    @import url($icon-font);
  }
}
