// stylelint-disable declaration-no-important

/**
Antialiasing
 */
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.text-truncate {
  @include text-truncate();
}

.second-ff {
  font-family: $font-family-sans-serif-heading;
}