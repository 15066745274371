@keyframes progress-indeterminate {
  0% {
    right: 100%;
    left: -35%;
  }

  100%,
  60% {
    right: -90%;
    left: 100%;
  }
}

/**
Progress
 */
.progress {
  position: relative;
  width: 100%;
  line-height: $progress-height;
  appearance: none;

  &::-webkit-progress-bar {
    background: var(--#{$prefix}progress-bg);
  }

  &::-webkit-progress-value {
    background-color: var(--#{$prefix}primary);
  }

  &::-moz-progress-bar {
    background-color: var(--#{$prefix}primary);
  }

  &::-ms-fill {
    background-color: var(--#{$prefix}primary);
    border: none;
  }
}

.progress-sm {
  height: .25rem;
}

/**
Progress bar
 */
.progress-bar {
  height: 100%;
}

.progress-bar-indeterminate {
  &:after,
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: inherit;
    will-change: left, right;
  }

  &:before {
    animation: progress-indeterminate 1.5s cubic-bezier(.65, .815, .735, .395) infinite;
  }
}

.progress-separated {
  .progress-bar {
    box-shadow: 0 0 0 2px var(--#{$prefix}card-bg, var(--#{$prefix}bg-surface));
  }
}


/**
Progressbg
 */
.progressbg {
  position: relative;
  padding: .25rem .5rem;
  display: flex;
}

.progressbg-text {
  position: relative;
  z-index: 1;
  @include text-truncate;
}

.progressbg-progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  background: transparent;
  pointer-events: none;
}

.progressbg-value {
  font-weight: var(--#{$prefix}font-weight-medium);
  margin-left: auto;
  padding-left: 2rem;
}