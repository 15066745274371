// stylelint-disable declaration-no-important

.ribbon {
  --#{$prefix}ribbon-margin: #{$card-ribbon-margin};
  --#{$prefix}ribbon-border-radius: #{$card-ribbon-border-radius};
  position: absolute;
  top: .75rem;
  right: calc(-1 * var(--#{$prefix}ribbon-margin));
  z-index: 1;
  padding: .25rem .75rem;
  font-size: $card-ribbon-font-size;
  font-weight: var(--#{$prefix}font-weight-bold);
  line-height: 1;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  background: var(--#{$prefix}primary);
  border-color: var(--#{$prefix}primary);
  border-radius: var(--#{$prefix}ribbon-border-radius) 0 var(--#{$prefix}ribbon-border-radius) var(--#{$prefix}ribbon-border-radius);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2rem;
  min-width: 2rem;

  &:before {
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 0;
    height: 0;
    content: "";
    filter: brightness(70%);
    border: calc(var(--#{$prefix}ribbon-margin) * .5) var(--#{$prefix}border-style);
    border-color: inherit;
    border-top-color: transparent;
    border-right-color: transparent;
  }

  @if $enable-extra-colors {
    @each $color, $value in $extra-colors {
      &.bg-#{$color} {
        border-color: var(--#{$prefix}#{$color});
      }

      &.bg-#{$color}-lt {
        border-color: rgba(var(--#{$prefix}#{$color}-rgb), .1) !important;
      }
    }
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
  }
}

.ribbon-top {
  top: calc(-1 * var(--#{$prefix}ribbon-margin));
  right: .75rem;
  width: 2rem;
  padding: .5rem 0;
  border-radius: 0 var(--#{$prefix}ribbon-border-radius) var(--#{$prefix}ribbon-border-radius) var(--#{$prefix}ribbon-border-radius);

  &:before {
    top: 0;
    right: 100%;
    bottom: auto;
    border-color: inherit;
    border-top-color: transparent;
    border-left-color: transparent;
  }

  &.ribbon-start {
    right: auto;
    left: .75rem;

    &:before {
      top: 0;
      right: 100%;
      left: auto;
    }
  }
}

.ribbon-start {
  right: auto;
  left: calc(-1 * var(--#{$prefix}ribbon-margin));

  &:before {
    top: auto;
    bottom: 100%;
    left: 0;
    border-color: inherit;
    border-top-color: transparent;
    border-left-color: transparent;
  }
}

.ribbon-bottom {
  top: auto;
  bottom: .75rem;
}

.ribbon-bookmark {
  padding-left: .25rem;
  border-radius: 0 0 var(--#{$prefix}ribbon-border-radius) 0;

  &:after {
    position: absolute;
    top: 0;
    right: 100%;
    display: block;
    width: 0;
    height: 0;
    content: "";
    border: 1rem var(--#{$prefix}border-style);
    border-color: inherit;
    border-right-width: 0;
    border-left-color: transparent;
    border-left-width: .5rem;
  }

  &.ribbon-left {
    padding-right: .5rem;

    &:after {
      right: auto;
      left: 100%;
      border-right-color: transparent;

      border-right-width: .5rem;
      border-left-width: 0;
    }
  }

  &.ribbon-top {
    padding-right: 0;
    padding-bottom: .25rem;
    padding-left: 0;
    border-radius: 0 var(--#{$prefix}ribbon-border-radius) 0 0;

    &:after {
      top: 100%;
      right: 0;
      left: 0;
      border-color: inherit;
      border-width: 1rem;
      border-top-width: 0;
      border-bottom-color: transparent;
      border-bottom-width: .5rem;
    }
  }
}

