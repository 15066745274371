.header{
	z-index: $zindex-fixed;
}
.sidebar {
	z-index: $zindex-offcanvas-backdrop;
}

.header {
	@include media-breakpoint-down(lg) {
		max-height: 0;
		transition: all .5s ease;
		opacity: 0;
		min-height: 0;
		&.opened {
			max-height: 100px;
			opacity: 1;
		}
	}
}
