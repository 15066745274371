.table {
	thead {
		th {
			color: $table-th-color;
			background: $table-th-bg;
			@include subheader;
			padding-top: $table-th-padding-y;
			padding-bottom: $table-th-padding-y;
			white-space: nowrap;

			@media print {
				background: transparent;
			}
		}
	}
}

.table-responsive {
	.table {
		margin-bottom: 0;
	}

	+ .card-footer {
		border-top: 0;
	}
}

.table-transparent {
	thead {
		th {
			background: transparent;
		}
	}
}

.table-nowrap {
	> :not(caption) > * > * {
		white-space: nowrap;
	}
}

.table-vcenter {
	> :not(caption) > * > * {
		vertical-align: middle;
	}
}

.table-center {
	> :not(caption) > * > * {
		text-align: center;
	}
}

.td-truncate {
	max-width: 1px;
	width: 100%;
}

.table-mobile {
	@each $breakpoint, $breakpoint-max-widthin in $grid-breakpoints {
		&#{breakpoint-infix($breakpoint)} {
			@include media-breakpoint-down($breakpoint) {
				display: block;

				thead {
					display: none;
				}

				tbody,
				tr {
					display: flex;
					flex-direction: column;
				}

				td {
					display: block;
					padding: $table-cell-padding-x $table-cell-padding-y !important;
					border: none;
					color: var(--#{$prefix}body-color) !important;

					&[data-label] {
						&:before {
							@include subheader;
							content: attr(data-label);
							display: block;
						}
					}
				}

				tr {
					border-bottom: var(--#{$prefix}border-width)
					var(--#{$prefix}border-style) $table-border-color;
				}

				.btn {
					display: block;
				}
			}
		}
	}
}

/**
Table sort
 */
.table-sort {
	font: inherit;
	color: inherit;
	text-transform: inherit;
	letter-spacing: inherit;
	border: 0;
	background: inherit;
	display: block;
	width: 100%;
	text-align: inherit;
	@include transition(color $transition-time);

	margin: (-$table-th-padding-y) (-$table-th-padding-x);
	padding: $table-th-padding-y $table-th-padding-x;

	&:hover,
	&.asc,
	&.desc {
		color: var(--#{$prefix}body-color);
	}

	&:after {
		content: "";
		display: inline-flex;
		width: 1rem;
		height: 1rem;
		vertical-align: bottom;
		mask-image: $table-sort-bg-image;
		background: currentColor;
		margin-left: .25rem;
	}

	&.asc:after {
		mask-image: $table-sort-desc-bg-image;
	}

	&.desc:after {
		mask-image: $table-sort-asc-bg-image;
	}
}

.table-borderless {
	thead th {
		background: transparent;
	}
}

// 48 - md
// 62 - lg
.table-custom {
	width: 100%;
	//margin-bottom: 1.5em;
	border-spacing: 0;
	@include media-breakpoint-up(md) {
		font-size: 0.9em;
	}
	@include media-breakpoint-up(lg) {
		font-size: 1em;
	}

	thead {
		position: absolute;
		clip: rect(1px 1px 1px 1px);
		/* IE6, IE7 */
		padding: 0;
		border: 0;
		height: 1px;
		width: 1px;
		overflow: hidden;
		@include media-breakpoint-up(lg) {
			position: relative;
			clip: auto;
			height: auto;
			width: auto;
			overflow: auto;
		}

		th {
			//background-color: #26890d;
			//border: 1px solid #86bc25;
			//font-weight: normal;
			//text-align: center;
			//color: white;

			&:first-of-type {
				text-align: left;
			}
		}
	}

	tbody,
	tr,
	th,
	td {
		display: block;
		//padding: 0;
		text-align: left;
		white-space: normal;
	}

	tr {
		@include media-breakpoint-up(lg) {
			display: table-row;
		}
	}

	th,
	td {
		padding: 0.35rem;
		vertical-align: middle;
		@include media-breakpoint-up(md) {
			//padding: 0.75em 0.5em;
		}
		@include media-breakpoint-up(lg) {
			display: table-cell;
			//padding: 0.75em 0.5em;
		}
		@include media-breakpoint-up(xl) {
			//padding: 0.75em;
		}
	}

	tbody {
		@include media-breakpoint-up(lg) {
			display: table-row-group;
		}

		tr {
			position: relative;
			//margin-bottom: 1em;
			@include media-breakpoint-up(lg) {
				display: table-row;
				//border-width: 1px;
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			@include media-breakpoint-up(md) {
				&:nth-of-type(even) {
					//background-color: rgba(0, 0, 0, 0.12);
				}
			}
		}

		th[scope=row] {
			padding: .65rem .5rem;
			@include media-breakpoint-down(lg) {
				background-color: var(--#{$prefix}bg-surface-secondary);
				box-shadow: inset 0 0 0 9999px var(--#{$prefix}bg-surface-secondary);
				a, button {
					font-weight: 600;
				}
			}
			@include media-breakpoint-up(sm) {
				//border-left: 1px solid #86bc25;
				//border-bottom: 1px solid #86bc25;
			}
			@include media-breakpoint-up(lg) {
				//background-color: transparent;
				//box-shadow: none;
				//color: #000001;
				//text-align: left;
			}
		}

		td {

			@include media-breakpoint-down(lg) {
				padding-left: 25%;
			}
			@include media-breakpoint-down(sm) {
				padding-left: 35%;
			}
			//text-align: right;
			@include media-breakpoint-up(lg) {
				//border-left: 1px solid #86bc25;
				//border-bottom: 1px solid #86bc25;
				//text-align: inherit;
				&:last-of-type {
					//border-right: 1px solid #86bc25;
				}
			}
		}

		td[data-title] {
			position: relative;

			&:before {
				position: absolute;
				top: 50%;
				left: 0.5rem;
				transform: translateY(-50%);
				content: attr(data-title);
				font-size: 0.625rem;
				font-weight: var(--#{$prefix}font-weight-bold);
				text-transform: uppercase;
				letter-spacing: 0.04em;
				color: var(--#{$prefix}body-color);
				@include media-breakpoint-up(lg) {
					content: none;
				}
			}
		}
	}

	.table-actions {
		//@include media-breakpoint-up(lg) {
		//	display: flex;
		//	justify-content: end;
		//	align-items: center;
		//}
		@include media-breakpoint-down(lg) {
			position: absolute;
			top: 0;
			right: 0;
			background: transparent;
			box-shadow: none;
			padding: .35rem .5rem;
		}
	}

	@include media-breakpoint-down(lg) {
		&.table-striped > tbody > tr:nth-of-type(2n) > * {
			--#{$prefix}table-accent-bg: transparent;
		}

		&.table-hover > tbody > tr:hover > * {
			--#{$prefix}table-accent-bg: var(--#{$prefix}table-hover-bg);
		}
	}


	.book-cover {
		max-width: 70px;
		@include media-breakpoint-up(lg) {
			max-width: 50px;
			min-width: 50px;
		}
	}

	.form-check-input {
		margin-left: -.95rem;
	}
}
.table-actions {
	width: 85px;
	border: 0;
	&.x1 {
		width: 60px;
	}
	.btn-list {
		justify-content: end;
	}
}
.more-action {
	//display: flex;
	//justify-content: center;
	//align-items: center;
	//width: 40px;
	//height: 20px;
	text-decoration: none;
	color: var(--#{$prefix}emphasis-color);

	@include media-breakpoint-down(sm) {
		//background: #eceef2;
		//padding: 0.5rem 0.55rem 0.35rem;
		//border-radius: 10.45rem;
		//height: 40px;

		&:hover {
			//background: #f1f3f6;
		}
	}
}