.breadcrumb {
  --#{$prefix}breadcrumb-item-active-font-weight: #{$breadcrumb-active-font-weight};
  --#{$prefix}breadcrumb-item-disabled-color: #{$breadcrumb-disabled-color};
  --#{$prefix}breadcrumb-link-color: #{$breadcrumb-link-color};
  padding: 0;
  margin: 0;
  background: transparent;

  a {
    color: var(--#{$prefix}breadcrumb-link-color);

    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb-muted {
  --#{$prefix}breadcrumb-link-color: var(--#{$prefix}secondary);
}

.breadcrumb-item {
  &.active {
    font-weight: var(--#{$prefix}breadcrumb-item-active-font-weight);

    a {
      color: inherit;
      pointer-events: none;
    }
  }

  &.disabled {
    color: var(--#{$prefix}breadcrumb-item-disabled-color);

    &:before {
      color: inherit;
    }

    a {
      color: inherit;
      pointer-events: none;
    }
  }
}

@each $name, $symbol in $breadcrumb-variants {
  .breadcrumb-#{$name} {
    --#{$prefix}breadcrumb-divider: "#{quote($symbol)}";
  }
}
