.menu-items {
  .menu-item {
    .menu-item-title {
      font-size: .85rem;
      font-weight: 600;
    }

    .menu-item-body {
      border: 1px solid var(--#{$prefix}border-color);
      padding: 0.45rem 0.75rem;
      margin-bottom: 0.45rem;
      border-radius: 0.25rem;

      &:hover {
        background: var(--#{$prefix}active-bg);
      }
    }

    //.collapse-menu-item,
    //.delete-menu-item,
    //.menu-item-handler {
    //  font-size: 1.25rem;
    //  opacity: .35;
    //
    //  &:hover {
    //    opacity: 1;
    //    cursor: pointer;
    //  }
    //}

    .menu-item-handler {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .nested-sortable {
    .nested-sortable {
      margin-left: 2rem;
    }
  }
}