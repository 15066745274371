// stylelint-disable declaration-no-important

/*
Scrollable
*/
.scrollable {
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&.hover {
		overflow-y: hidden;

		> * {
			margin-top: -1px;
		}

		&:hover,
		&:focus,
		&:active {
			overflow: visible;
			overflow-y: auto;
		}
	}

	.touch & {
		overflow-y: auto !important;
	}
}

.scroll-x,
.scroll-y {
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
}

.scroll-y {
	overflow-y: auto;
}

.scroll-x {
	overflow-x: auto;
}

.no-scroll {
	overflow: hidden;
}


/* Firefox */
.scroll-x,
.scroll-y {
	scrollbar-color: $gray-100 transparent;
	scrollbar-width: thin;

	&.scroll-dark {
		scrollbar-color: $gray-300 transparent;
	}
}

/* WebKit and Chromiums */
.scroll-x::-webkit-scrollbar-track,
.scroll-y::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	border-radius: 10px;
	background-color: transparent;
}

.scroll-x::-webkit-scrollbar,
.scroll-y::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
}

.scroll-y::-webkit-scrollbar-thumb,
.scroll-y::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: none;
	background-color: $gray-100;

	&.scroll-dark {
		background-color: $gray-300;
	}
}

.scroll-x:hover::-webkit-scrollbar-thumb,
.scroll-y:hover::-webkit-scrollbar-thumb {
	background-color: shade-color($gray-100, 5%);

	&.scroll-dark {
		background-color: shade-color($gray-300, 5%);
	}
}