/**
Bootstrap color input
 */
.form-control-color {
  &::-webkit-color-swatch {
    border: none;
  }
}

/**
Remove the cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/**
Form control dark theme fix
 */
.form-control {
  &::file-selector-button {
    background-color: var(--#{$prefix}btn-color, #{$form-file-button-bg});
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--#{$prefix}btn-color, #{$form-file-button-hover-bg});
  }
}

.bounce-form-element {
  outline: 0;
  border-color: var(--#{$prefix}danger);
  animation-name: bounce;
  animation-duration: .5s;
  animation-delay: 0.25s;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    animation-timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    animation-timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    animation-timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    animation-timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    animation-timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
}
.hide-remove-btn {
  pointer-events: none;
  .remove {
    width: 0;
    padding: 0 !important;
    border: 0 !important;
  }
}