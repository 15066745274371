//
// Base styles
//

.datagrid {
  --#{$prefix}datagrid-padding: #{$datagrid-padding};
  --#{$prefix}datagrid-item-width: #{$datagrid-item-width};

  display: grid;
  grid-gap: var(--#{$prefix}datagrid-padding);
  grid-template-columns: repeat(auto-fit, minmax(var(--#{$prefix}datagrid-item-width), 1fr));
}

.datagrid-title {
  @include subheader;
  color: var(--#{$prefix}blue-darken); // new
  margin-bottom: .25rem;
}