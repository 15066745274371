.img-responsive {
	--#{$prefix}img-responsive-ratio: #{percentage(.75)};
	background: no-repeat center/cover;
	padding-top: var(--#{$prefix}img-responsive-ratio);
}

.img-responsive-grid {
	padding-top: calc(var(--#{$prefix}img-responsive-ratio) - calc(var(--#{$prefix}gutter-y) / 2));
}

@each $key, $ratio in $aspect-ratios {
	.img-responsive-#{$key} {
		--#{$prefix}img-responsive-ratio: #{$ratio};
	}
}

.image-uploader {
	position: relative;
	//margin: 0 auto;
	overflow: hidden;
}

.image-uploader-action {
	--#{$prefix}btn-hover-color: var(--#{$prefix}primary);
	display: flex;
	flex-direction: column;
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 1;
}

.image-uploader-input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
}


.image-uploader-preview {
	width: 100%; //192px
	height: 208px;
	position: relative;
	border-radius: var(--#{$prefix}border-radius);
	background: var(--#{$prefix}bg-forms);
	border: 1px solid var(--#{$prefix}border-color);
	padding: 5px;
	//box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 100%;
		max-height: 100%;
		height: auto;
		pointer-events: none;
	}

	> div {
		width: 100%;
		height: 100%;
		//border-radius: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
}