:root,
:host {
  font-size: 16px;
  height: 100%;
}

:root,
:host,
[data-bs-theme="light"] {
  @each $name, $color in map-merge($theme-colors, $gray-colors) {
    --#{$prefix}#{$name}: #{$color};
    --#{$prefix}#{$name}-rgb: #{to-rgb($color)};
    --#{$prefix}#{$name}-fg: #{if(contrast-ratio($color) > $min-contrast-ratio, var(--#{$prefix}light), var(--#{$prefix}dark))};
    --#{$prefix}#{$name}-darken: #{theme-color-darker($color)};
    --#{$prefix}#{$name}-lt: #{theme-color-lighter($color)};
    --#{$prefix}#{$name}-lt-rgb: #{to-rgb(theme-color-lighter($color))};
  }

  @each $name, $value in $spacers {
    --#{$prefix}spacer-#{$name}: #{$value};
  }
  --#{$prefix}spacer: #{$spacer};

  --#{$prefix}bg-surface: #{$bg-surface};
  --#{$prefix}bg-surface-secondary: #{$bg-surface-secondary};
  --#{$prefix}bg-surface-tertiary: #{$bg-surface-tertiary};
  --#{$prefix}bg-surface-dark: #{$bg-surface-dark};
  --#{$prefix}bg-forms: var(--#{$prefix}bg-surface);

  --#{$prefix}border-color: #{$border-color};
  --#{$prefix}border-color-translucent: #{$border-color-translucent};
  --#{$prefix}border-dark-color: #{$border-dark-color};
  --#{$prefix}border-dark-color-translucent: #{$border-dark-color-translucent};
  --#{$prefix}border-active-color: #{$border-active-color};

  --#{$prefix}icon-color: #{$icon-color};

  --#{$prefix}active-bg: #{$active-bg};

  --#{$prefix}disabled-bg: #{$disabled-bg};
  --#{$prefix}disabled-color: #{$disabled-color};

  --#{$prefix}code-color: #{$code-color};
  --#{$prefix}code-bg: #{$code-bg};

  --#{$prefix}dark-mode-border-color: #{$border-color-dark};
  --#{$prefix}dark-mode-border-color-translucent: #{$border-color-translucent-dark};
  --#{$prefix}dark-mode-border-color-active: #{$border-color-active-dark};
  --#{$prefix}dark-mode-border-dark-color: #{$border-dark-color-dark};

  --#{$prefix}page-padding: #{$page-padding};
  --#{$prefix}page-padding-y: #{$page-padding-y};
  @include media-breakpoint-down($cards-grid-breakpoint) {
    --#{$prefix}page-padding: #{$page-padding-sm};
  }

  @each $name, $value in $font-weights {
    --#{$prefix}font-weight-#{$name}: #{$value};
  }

  @each $name, $value in $font-sizes {
    --#{$prefix}font-size-h#{$name}: #{$value};
  }

  @each $name, $value in $line-heights {
    --#{$prefix}line-height-#{$name}: #{$value};
  }

  @each $name, $value in $box-shadows {
    --#{$prefix}#{$name}: #{$value};
  }
}