.badge {
  justify-content: center;
  align-items: center;
  background: $badge-bg-color;
  overflow: hidden;
  user-select: none;
  border: var(--#{$prefix}border-width) var(--#{$prefix}border-style) transparent;
  min-width: ($badge-padding-y * 2 ) + (divide($badge-font-size, 100%) * 1em);
  font-weight: $headings-font-weight;
  letter-spacing: .04em;
  vertical-align: bottom;

  @at-root a#{&} {
    color: $card-bg;
  }

  .avatar {
    box-sizing: content-box;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 .5rem 0 -.5rem;
  }

  .icon {
    width: 1em;
    height: 1em;
    font-size: 1rem;
    stroke-width: 2;
  }
}

 .badge:empty,
 .badge-empty {
  display: inline-block;
  width: $badge-empty-size;
  height: $badge-empty-size;
  min-width: 0;
  min-height: auto;
  padding: 0;
  border-radius: $border-radius-pill;
  vertical-align: baseline;
}

//
// Outline badge
//
.badge-outline {
  background-color: transparent;
  border: var(--#{$prefix}border-width) var(--#{$prefix}border-style) currentColor;
}

//
// Pill badge
//
.badge-pill {
  border-radius: $border-radius-pill;
}

//
// Badges list
//
.badges-list {
  @include elements-list;
}

//
// Notification badge
//
.badge-notification {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%);
  z-index: 1;
}

.badge-blink {
  animation: blink 2s infinite;
}