.offcanvas-header {
  border-bottom: var(--#{$prefix}border-width) var(--#{$prefix}border-style) $border-color-translucent;
}

.offcanvas-footer {
  padding: $offcanvas-padding-y $offcanvas-padding-x;
}

.offcanvas-title {
  font-size: $h3-font-size;
  font-weight: var(--#{$prefix}font-weight-medium);
  line-height: 1.5rem;
}

.offcanvas-narrow {
  width: 20rem;
}