.kaa-popup {
  display: none;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, .3);

  &.is-visible {
    display: flex;

    .kaa-popup-container {
      animation: kaa-popup-show 0.3s;
    }
  }

  &.is-hidden {
    background-color: transparent;

    .kaa-popup-container {
      animation: kaa-popup-hide 0.15s forwards;
    }
  }
}
.kaa-popup-container {
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  z-index: 1070;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 5px;
  background: var(--#{$prefix}bg-surface);
  font-family: inherit;
  font-size: 1rem;
}
.kaa-popup-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  //color: $body-color;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.kaa-popup-icon {
  display: flex;
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid #000;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;

  .kaa-popup-icon-content {
    display: flex;
    align-items: center;
    font-size: 3.75em;

    svg {
      width: 3.75rem;
      height: 3.75rem;
    }
  }

  &.warning {
    border-color: lighten($orange, 10%);
    color: $orange;
  }

  &.success {
    border-color: lighten($green, 10%);
    color: $green;
  }

  &.error {
    border-color: lighten($red, 10%);
    color: $red;
  }

  &.info {
    border-color: lighten($blue, 10%);
    color: $blue;
  }

  &.question {
    border-color: lighten($cyan, 10%);
    color: $cyan;
  }
}
.kaa-popup-close {
  position: absolute;
  top: .5rem;
  right: .25rem;
  border: 0;
  background: transparent;
  //font-size: 2rem;
  //line-height: 1;
  color: var(--#{$prefix}secondary);
  opacity: .15;

  svg {
    width: 2rem;
    height: 2rem;
    stroke-width: 2;
    pointer-events: none;
  }

  &:hover {
    opacity: .85;
  }
}
.kaa-popup-text {
  color: var(--#{$prefix}secondary);
  text-align: center;
}
.kaa-popup-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1.25em auto 0;
  padding: 0;

  .kaa-popup-button {
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    box-shadow: none;
    font-weight: 500;


    &.kaa-popup-confirm {
      border: 0;
      border-radius: 0.25em;
      background: initial;
      background-color: $red-400;
      color: #fff;
      font-size: 1em;

      &.warning {
        border-color: lighten($orange, 10%);
        background-color: $orange;
      }

      &.success {
        border-color: lighten($green, 10%);
        background-color: $green;
      }

      &.error {
        border-color: lighten($red, 10%);
        background-color: $red;
      }

      &.info {
        border-color: lighten($blue, 10%);
        background-color: $blue;
      }

      &.question {
        border-color: lighten($cyan, 10%);
        background-color: $cyan;
      }
    }

    &.kaa-popup-cancel {
      border: 0;
      border-radius: 0.25em;
      background-color: transparent;
      color: $gray-700;
      font-size: 1em;
    }

    &.is-loading {
      transition: all 0.3s ease-in-out;
      color: transparent;
      position: relative;
      pointer-events: none;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        height: .5rem;
        width: .5rem;
        background: #fff;
        border-radius: 100%;
        transition: all 0.4s linear;
        animation: left-right-bounce 1s infinite;
        opacity: 1;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    &:hover {
      background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
  }
}

@keyframes left-right-bounce {
  0% {
    left: 30%;
  }
  50% {
    left: 70%;
  }
  100% {
    left: 30%;
  }
}
@keyframes kaa-popup-show {
  0% {
    transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes kaa-popup-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(.5);
    opacity: 0;
  }
}