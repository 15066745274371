.animated-link {
	color: var(--#{$prefix}gray-400);

	background: linear-gradient(to right, currentColor 0%, currentColor 100%) no-repeat left 100%;

	background-size: 0 6%;
	transition-duration: .5s;
	//text-decoration: none !important;
	font-weight: inherit;
	padding: 0;

	&:hover {
		background-size: 100% 6%;
	}
}