//
// Steps
//
.steps {
  --#{$prefix}steps-color: #{$steps-color};
  --#{$prefix}steps-inactive-color: #{$steps-inactive-color};
  --#{$prefix}steps-dot-size: .5rem;
  --#{$prefix}steps-border-width: #{$steps-border-width};
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

@each $name, $color in $extra-colors {
  .steps-#{$name} {
    --#{$prefix}steps-color: var(--#{$prefix}#{$name});
  }
}

//
// Step item
//
.step-item {
  position: relative;
  flex: 1 1 0;
  min-height: 1rem;
  margin-top: 0;
  color: inherit;
  text-align: center;
  cursor: default;
  padding-top: calc(var(--#{$prefix}steps-dot-size));

  @at-root a#{&} {
    cursor: pointer;

    &:hover {
      color: inherit;
    }
  }

  &:after,
  &:before {
    background: var(--#{$prefix}steps-color);
  }

  &:not(:last-child):after {
    position: absolute;
    left: 50%;
    width: 100%;
    content: "";
    transform: translateY(-50%);
  }

  &:after {
    top: calc(var(--#{$prefix}steps-dot-size) * .5);
    height: var(--#{$prefix}steps-border-width);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-pill;
    transform: translateX(-50%);
    color: var(--#{$prefix}white);
    width: var(--#{$prefix}steps-dot-size);
    height: var(--#{$prefix}steps-dot-size);
  }

  &.active {
    font-weight: var(--#{$prefix}font-weight-bold);

    &:after {
      background: var(--#{$prefix}steps-inactive-color);
    }

    & ~ .step-item {
      color: var(--#{$prefix}disabled-color);

      &:after,
      &:before {
        background: var(--#{$prefix}steps-inactive-color);
      }
    }
  }
}

//
// Steps counter
//
.steps-counter {
  --#{$prefix}steps-dot-size: 1.5rem;
  counter-reset: steps;

  .step-item {
    counter-increment: steps;

    &:before {
      content: counter(steps);
    }
  }
}

//
// Steps vertical
//
.steps-vertical {
  --#{$prefix}steps-dot-offset: 6px;
  flex-direction: column;

  &.steps-counter {
    --#{$prefix}steps-dot-offset: -2px;
  }

  .step-item {
    text-align: left;
    padding-top: 0;
    padding-left: calc(var(--#{$prefix}steps-dot-size) + 1rem);
    min-height: auto;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    &:before {
      top: var(--#{$prefix}steps-dot-offset);
      left: 0;
      transform: translate(0, 0);
    }

    &:not(:last-child) {
      &:after {
        position: absolute;
        content: '';
        transform: translateX(-50%);
        top: var(--#{$prefix}steps-dot-offset);
        left: calc(var(--#{$prefix}steps-dot-size) * 0.5);
        width: var(--#{$prefix}steps-border-width);
        height: calc(100% + 1rem);
      }
    }
  }
}