@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: relative;
  display: block;
  width: $loader-size;
  height: $loader-size;
  color: $blue;
  vertical-align: middle;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    border: 1px solid;
    border-color: transparent;
    border-top-color: currentColor;
    border-left-color: currentColor;

    border-radius: $border-radius-pill;
    animation: loader .6s linear;
    animation-iteration-count: infinite;
  }
}

/*
.dimmer {
  position: relative;

  .loader {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    display: none;
    margin: 0 auto;
    transform: translateY(-50%);
  }

  &.active {
    .loader {
      display: block;
    }

    .dimmer-content {
      pointer-events: none;
      opacity: .1;
    }
  }
}
*/

/*
@keyframes animated-dots {
  0% {
    transform: translateX(-100%);
  }
}

.animated-dots {
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;

  &:after {
    display: inline-block;
    content: "...";
    animation: animated-dots 1.2s steps(4, jump-none) infinite;
  }
}
*/


.input-container {
  position: relative;
  width: 100%;
}
.input-loading {
  .loading {
    position: absolute;
    top: 50%;
    right: 0;
    width: 24px;
    height: 24px;
    margin: 0 .5rem 0 0;
    transform: translateY(-50%);
  }

  .loading:before {
    position: absolute;
    display: block;
    content: "";
    z-index: 12;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--#{$prefix}bg-surface);
  }

  .loading:after {
    position: absolute;
    display: block;
    content: "";
    z-index: 11;
    width: 12px;
    height: 12px;
    border-radius: 200px 0 0;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0) 0, $primary 50%, $primary 100%);
    animation: loading 0.5s linear infinite;
  }

  @keyframes loading {
    0% {
      transform-origin: 100% 100%;
      transform: rotate(0deg);
    }
    100% {
      transform-origin: 100% 100%;
      transform: rotate(360deg);
    }
  }

  .input-group-text {
    * {
      visibility: hidden;
    }
  }
}

.input-group {
  .input-container {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;

    .form-control {
      height: 100%;
    }
  }
}


.loading-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--#{$prefix}emphasis-color-rgb), 0.01);
  cursor: progress;
  z-index: 9;
  border-radius: 1.25rem;

  .loading-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.loading-spinner {
  &.loading {
    float: left;
    border-radius: 50%;
    //margin           : 10px 5px 10px 0px;
    width: 20px;
    height: 20px;
    border: 3px solid rgba($border-color, 0.1);
    border-top-color: $border-color;
    animation: spin 1s infinite linear;

    &.loading-primary {
      border-color: rgba($primary, 0.3);
      border-top-color: $primary;
    }

    &.loading-info {
      border-color: rgba($blue, 0.3);
      border-top-color: $blue;
    }

    &.loading-success {
      border-color: rgba($green, 0.3);
      border-top-color: $green;
    }

    &.loading-warning {
      border-color: rgba($orange, 0.3);
      border-top-color: $orange;
    }

    &.loading-danger {
      border-color: rgba($red, 0.3);
      border-top-color: $red;
    }
  }

  &.pulse {
    float: left;
    position: relative;
    width: 5px;
    height: 20px;
    //margin          : 10px 15px 10px 10px;
    background: rgba($border-color, 0.1);
    animation: pulse 750ms infinite;
    animation-delay: 250ms;

    &:before, &:after {
      content: '';
      position: absolute;
      display: block;
      height: 12px;
      width: 5px;
      background: rgba($border-color, 0.1);
      top: 50%;
      transform: translateY(-50%);
      animation: pulse 750ms infinite;
    }

    &:before {
      left: -10px;
    }

    &:after {
      left: 10px;
      animation-delay: 250ms;
    }

    &.pulse-primary {
      background: $primary;

      &:before, &:after {
        background: $primary;
      }
    }

    &.pulse-info {
      background: $blue;

      &:before, &:after {
        background: $blue;
      }
    }

    &.pulse-success {
      background: $green;

      &:before, &:after {
        background: $green;
      }
    }

    &.pulse-warning {
      background: $orange;

      &:before, &:after {
        background: $orange;
      }
    }

    &.pulse-danger {
      background: $red;

      &:before, &:after {
        background: $red;
      }
    }
  }

  &.scaleout {
    float: left;
    //margin            : 10px 5px 10px 0px;
    width: 20px;
    height: 20px;
    background-color: $border-color;
    border-radius: 100%;
    -webkit-animation: scaleout 1s infinite ease-in-out;
    animation: scaleout 1s infinite ease-in-out;

    &.scaleout-primary {
      background-color: $primary;
    }

    &.scaleout-info {
      background-color: $blue;
    }

    &.scaleout-success {
      background-color: $green;
    }

    &.scaleout-warning {
      background-color: $orange;
    }

    &.scaleout-danger {
      background-color: $red;
    }
  }

  &.rotating {
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    //margin    : 10px 5px 10px 0px;
    animation: rotate 1.4s infinite ease-in-out, background 1.4s infinite ease-in-out alternate;
  }

  &.snake {
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    //margin             : 10px 5px 10px 0px;
    border: 3px solid $primary;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spin 1s infinite linear;
  }

  &.pulsate {
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    //margin        : 10px 5px 10px 0px;
    border: 3px solid $primary;
    border-radius: 50%;
    animation: pulsate 1s infinite linear;
  }
}

@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0)
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    background: $border-color;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}

@keyframes background {
  0% {
    background-color: $primary;
  }
  50% {
    background-color: $blue;
  }
  100% {
    background-color: $green;
  }
}

// for tom-select
.bounce-spinner {
  width: 40px;
  text-align: center;

  > div {
    width: 10px;
    height: 10px;
    background-color: $primary;
    border-radius: 100%;
    display: inline-block;
    animation: bounce-spinner 1.4s infinite ease-in-out both;
  }

  .bounce-1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce-2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@keyframes bounce-spinner {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

.kaa-loader-container {
  display: flex;
  width: 100%;
  height: 350px;
  align-items: center;
  justify-content: center;
  &.kaa-loader-sm {
    height: 250px;
  }
  &.kaa-loader-md {
    height: 350px;
  }
  &.kaa-loader-lg {
    height: 450px;
  }
  &.kaa-loader-xl {
    height: 550px;
  }

}

.kaa-loader {
  width: 150px;
  height: 8px;
  display: inline-block;
  position: relative;
  background: rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: .15rem;

  &::after {
    content: '';
    width: 122px;
    height: 8px;
    background: var(--#{$prefix}emphasis-color);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: kaaloader 1.4s linear infinite;
  }
}

@keyframes kaaloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}