.nav-vertical {
	&,
	.nav {
		flex-direction: column;
		flex-wrap: nowrap;
	}

	.nav {
		margin-left: 1.25rem;
		border-left: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color);
		padding-left: .5rem;
	}

	.nav-link.active,
	.nav-item.show .nav-link {
		font-weight: var(--#{$prefix}font-weight-bold);
	}

	&.nav-pills {
		margin: 0 (-$nav-link-padding-x);
	}
}

//
// Nav bordered
//
.nav-bordered {
	border-bottom: $nav-bordered-border-width var(--#{$prefix}border-style) $nav-bordered-border-color;

	.nav-item {
		+ .nav-item {
			margin-left: $nav-bordered-margin-x;
		}
	}

	.nav-link {
		padding-left: 0;
		padding-right: 0;
		margin: 0 0 (- $nav-bordered-border-width);
		border: 0;
		border-bottom: $nav-bordered-link-active-border-width var(--#{$prefix}border-style) transparent;
		color: var(--#{$prefix}secondary);
	}

	.nav-link.active,
	.nav-item.show .nav-link {
		color: $nav-bordered-link-active-color;
		border-color: $nav-bordered-link-active-border-color;
	}

}

.nav-link {
	display: flex;
	@include transition(color $transition-time);
	align-items: center;
}

.nav-link-toggle {
	margin-left: auto;
	padding: 0 .25rem;
	@include transition(transform $transition-time);
	@include caret();

	&:after {
		margin: 0;
	}

	@at-root .nav-link[aria-expanded="true"] & {
		transform: rotate(180deg);
	}
}

.nav-link-icon {
	width: $nav-link-icon-size;
	height: $nav-link-icon-size;
	margin-right: .5rem;
	color: $nav-link-icon-color;

	svg {
		display: block;
		height: 100%;
	}
}

.nav-fill {
	.nav-item {
		.nav-link {
			justify-content: center;
		}
	}
}

.nav-tabs-scroll {
	//@include scrollbar;

	.nav-tabs {
		padding-bottom: .125rem;
	}

	.nav {
		overflow-x: auto;
		overflow-y: hidden;
		flex-wrap: nowrap;
		white-space: nowrap;
		scroll-behavior: smooth;

		.nav-item {
			white-space: nowrap;
		}
	}
}

.nav-tabs {


}

//.nav-tabs.nav-tabs-dropdown,
//.nav-tabs-dropdown {
//	@include media-breakpoint-down(lg) {
//		overflow: hidden;
//		position: relative;
//		padding: 0;
//		transition: padding .3s ease-out;
//
//		.nav-link {
//			border-bottom: 0.175rem solid transparent;
//			padding-top: 1.25rem;
//			padding-bottom: 1.25rem;
//			margin-bottom: -.125rem;
//
//			&:hover,
//			&:focus {
//				border-top-color: transparent;
//				border-left-color: transparent;
//				border-right-color: transparent;
//			}
//		}
//
//		.nav-link.active,
//		.nav-item.show .nav-link {
//			font-weight: 600;
//			border-top-color: transparent;
//			border-left-color: transparent;
//			border-right-color: transparent;
//		}
//
//		&::after {
//			content: "";
//			width: 24px;
//			height: 24px;
//			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='%23868fa5'%3E%3Cpath d='M21 18v2H3v-2h18zM17.404 3.904L22 8.5l-4.596 4.596-1.414-1.414L19.172 8.5 15.99 5.318l1.414-1.414zM12 11v2H3v-2h9zm0-7v2H3V4h9z' /%3E%3C/svg%3E");
//			position: absolute;
//			top: 50%;
//			right: 20px;
//			transform: translateY(-50%);
//			z-index: 2;
//			pointer-events: none;
//			transition: right .3s ease-out;
//		}
//
//		.nav-item {
//			width: 100%;
//			position: relative;
//		}
//		.nav-link {
//			width: 100%;
//		}
//
//		&.show {
//			//border: 5px solid #2d37481c;
//			padding: 1rem;
//
//			&::after {
//				top: 50px;
//				right: 35px;
//			}
//
//			.nav-link {
//				position: relative;
//				display: block;
//				opacity: 1;
//
//
//				//&:not(.active) {
//				//	padding-top: .85rem;
//				//	padding-bottom: .85rem;
//				//}
//
//
//				&.active {
//					background-color: #f5f9fc;
//				}
//			}
//
//			.nav-item {
//				.nav-link {
//					border: 1px solid $border-color;
//				}
//
//				&:first-child {
//					.nav-link {
//						border-top-left-radius: var(--#{$prefix}card-border-radius);
//						border-top-right-radius: var(--#{$prefix}card-border-radius);
//					}
//				}
//
//				&:last-child {
//					.nav-link {
//						border-bottom-left-radius: var(--#{$prefix}card-border-radius);
//						border-bottom-right-radius: var(--#{$prefix}card-border-radius);
//					}
//				}
//			}
//		}
//
//
//		.nav-link {
//			position: absolute;
//			margin: 0;
//			opacity: 0;
//
//			&.active {
//				position: relative;
//				z-index: 1;
//				opacity: 1;
//			}
//		}
//
//	}
//}

.select-tabs {
	--#{$prefix}form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='%23868fa5'%3E%3Cpath d='M21 18v2H3v-2h18zM17.404 3.904L22 8.5l-4.596 4.596-1.414-1.414L19.172 8.5 15.99 5.318l1.414-1.414zM12 11v2H3v-2h9zm0-7v2H3V4h9z' /%3E%3C/svg%3E");
	padding: 1.2563rem 2.25rem 1.2563rem 1.175rem;
	background-position: right 1.275rem center;
	background-size: 24px 24px;
	border-radius: var(--#{$prefix}border-radius) var(--#{$prefix}border-radius) 0 0;
	//border-bottom: 0;
	font-weight: 700;
	z-index: $zindex-fixed;
}

.tab-custom {
	@include media-breakpoint-down(lg) {
		> .card {
			border-top: 0;
			border-radius: 0 0 var(--#{$prefix}border-radius) var(--#{$prefix}border-radius);
		}
	}
}