.carousel {

}

.carousel-indicators-vertical {
  left: auto;
  top: 0;
  margin: 0 1rem 0 0;
  flex-direction: column;

  [data-bs-target] {
    margin: $carousel-indicator-spacer 0 $carousel-indicator-spacer;
    width: $carousel-indicator-height;
    height: $carousel-indicator-width;
    border: 0;
    border-left: $carousel-indicator-hit-area-height var(--#{$prefix}border-style) transparent;
    border-right: $carousel-indicator-hit-area-height var(--#{$prefix}border-style) transparent;
  }
}

.carousel-indicators-dot {
  [data-bs-target] {
    width: $carousel-indicator-dot-width;
    height: $carousel-indicator-dot-width;
    border-radius: $border-radius-pill;
    border: $carousel-indicator-hit-area-height var(--#{$prefix}border-style) transparent;
    margin: 0;
  }
}

.carousel-indicators-thumb {
  [data-bs-target] {
    width: $carousel-indicator-thumb-width * .5;
    height: auto;
    background: no-repeat center/cover;
    border: 0;
    border-radius: var(--#{$prefix}border-radius);
    box-shadow: $box-shadow;
    margin: 0 $carousel-indicator-spacer;
    opacity: $carousel-indicator-thumb-opacity;

    @include media-breakpoint-up(lg) {
      width: $carousel-indicator-thumb-width;
    }

    &:before {
      content: "";
      padding-top: var(--#{$prefix}aspect-ratio, 100%);
      display: block;
    }
  }

  &.carousel-indicators-vertical {
    [data-bs-target] {
      margin: $carousel-indicator-spacer 0;
    }
  }
}

.carousel-caption-background {
  background: red;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 90%;
  background: linear-gradient(0deg, rgba($dark, .9), rgba($dark, 0));
}